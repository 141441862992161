.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.date-picker .react-date-picker__inputGroup__day {
  width: 1rem;
}

#react-tiny-popover-container {
  z-index: 1000;
}

.plus-pack-form::-webkit-scrollbar {
  height: 1px !important;
}

.plus-pack-form::-webkit-scrollbar-track {
  background-color: transparent;
}

.plus-pack-form::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  opacity: 0.2;
  border-radius: 20px;
  border: 4px solid transparent;
  background-clip: content-box;
}

.plus-pack-form::-webkit-scrollbar-thumb:hover {
  border: 3px solid transparent;
  background-color: rgb(51, 51, 51, 0.5);
}

.review-overlay {
  position: absolute;
  bottom: 0;
  left: 35%;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  /* Adjust the blur value as needed */
  color: #ffffff;
  width: 30%;
  padding: 10px 5px;
  margin: 5px;
  margin-bottom: 15px;
  border-radius: 25px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.select__control {
  border: 1px solid #8d8e92 !important;
  border-radius: 8px !important;
  padding: 4px 0px !important;
  box-shadow: none !important;
  background-color: #fff;
}

.select__multi-value__remove {
  background-color: transparent !important;
}

.select__multi-value__label {
  font-size: 14px !important;
  line-height: 21px !important;
  font-weight: 400 !important;
  color: black !important;
}

.select__indicator-separator {
  display: none !important;
}

.select__multi-value {
  display: flex;
  align-items: center;
  background-color: #f5f5f5 !important;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 0.25rem !important;
  gap: 0.25rem !important;
}
